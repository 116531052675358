import { showCustomHint } from './custom-hint.js';
import { countryToPhoneCodeMap } from './phone-code.js';

jQuery(document).ready(function($) {
    let tokenTimeout;

    function setTokenTimeout() {
        clearTimeout(tokenTimeout);

        if (window.location.pathname.includes('/games/')) {
            // Don't set the timeout if the URL includes '/games/'
            return;
        }

        tokenTimeout = setTimeout(function() {
            window.sessionStorage.removeItem('token');
            handleSessionChange();
        }, 300000); // 5 minutes 
    }

    function handleSessionChange() {
        showCustomHint(customMessagesForm.sessionExpired, 'custom_session_expired');
        window.location.reload();
    }

    window.addEventListener('storage', function(event) {
        if (event.key === 'token') {
            handleSessionChange();
        }
    });

    $(document).on('mousemove keypress', function() {
        if (window.sessionStorage.getItem('token')) {
            setTokenTimeout();
        }
    });

    function getLanguageFromURL() {
        const pathArray = window.location.pathname.split('/');
        const languageCode = pathArray[1]; // Assuming language code is the first part of the path
        const supportedLanguages = ['de', 'pt', 'es', 'fr', 'it']; // Supported languages
        if (supportedLanguages.includes(languageCode)) {
            return languageCode;
        }
        return 'en'; // Default to English if no language code or unsupported language
    }

    const localeCode = getLanguageFromURL();

    function getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        const results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    const landingURI = window.location.href;
    const referralURI = document.referrer || '';
    const affiliateId = getUrlParameter('affid');
    const affiliateTag = getUrlParameter('token');

    $('#landing-uri').val(landingURI);
    $('#referral-uri').val(referralURI);
    $('#affiliate-id').val(affiliateId);
    $('#affiliate-tag').val(affiliateTag);

    $.getJSON('https://api.ipify.org?format=json', function(data) {
        $('#ip-address').val(data.ip);
    });

    $('#login-popup form').on('submit', function(e) {
        e.preventDefault();

        const $loginButton = $('.login-button-with-loading-animation');

        // Show loading state
        $loginButton.addClass('loading');
        $loginButton.find('.spinner').show();

        // Store the original text of the button
        const originalText = $loginButton.text().trim();

        // Remove any existing text nodes and replace with "Loading..."
        $loginButton.contents().filter(function() {
            return this.nodeType === 3; // NodeType 3 is a text node
        }).remove();

        $loginButton.append('Loading...'); // Add the new single text node

        $.getJSON('https://api.ipify.org?format=json', function(data) {
            const formData = {
                action: 'handle_login',
                email: $('#email').val(),
                password: $('#password').val(),
                nonce: myAjax.nonce,
                locale: localeCode,
            };

            $.post(myAjax.ajaxurl, formData, function(response) {
                // Hide loading state
                $loginButton.removeClass('loading');
                $loginButton.find('.spinner').hide();

                // Restore the original button text
                $loginButton.contents().filter(function() {
                    return this.nodeType === 3;
                }).remove();

                $loginButton.append(originalText);

                if (response.success) {
                    $('#login-popup').hide();
                    $('#modal-backdrop-semi-transparent').css('display', 'none');
                    if (response.data && response.data.token) {
                        if (window.sessionStorage.getItem('token') !== response.data.token) {
                            window.sessionStorage.setItem('token', response.data.token);
                            setTokenTimeout();
                            document.dispatchEvent(new Event('tokenSet'));
                            //checkUserProfile(true);
                        
                        }
                    
                    }

                    location.reload(); //need to refactor
                
                } else {
                    if (response.data && response.data.custom_code) {
                        switch (response.data.custom_code) {
                            case 'custom_wrong_password':
                                showCustomHint(customMessagesForm.wrongPassword, 'custom_wrong_password');
                                break;
                            case 'custom_this_email_is_linked':
                                showCustomHint(customMessagesForm.alreadyLinkedEmail, 'custom_this_email_is_linked');
                                break;
                            case 'INVALID_AGE':
                                showCustomHint(customMessagesForm.invalidAge, 'INVALID_AGE');
                                break;
                            case 'INVALID_CODE':
                                showCustomHint(customMessagesForm.invalidCode, 'INVALID_CODE');
                                break;
                            case 'INVALID_GENDER':
                                showCustomHint(customMessagesForm.invalidGender, 'INVALID_GENDER');
                                break;
                            case 'INVALID_LENGTH':
                                showCustomHint(customMessagesForm.invalidLength, 'INVALID_LENGTH');
                                break;
                            case 'INVALID_PHONE_FORMAT':
                                showCustomHint(customMessagesForm.invalidPhoneFormat, 'INVALID_PHONE_FORMAT');
                                break;
                            case 'LOGIN_NOT_ALLOWED':
                                showCustomHint(customMessagesForm.loginNotAllowed, 'LOGIN_NOT_ALLOWED');
                                break;
                            case 'NO_NATIONAL_ID':
                                showCustomHint(customMessagesForm.noNationalID, 'NO_NATIONAL_ID');
                                break;
                            case 'NO_PASSWORD':
                                showCustomHint(customMessagesForm.noPassword, 'NO_PASSWORD');
                                break;
                            case 'PROVIDER_RESTRICTED_COUNTRY':
                                showCustomHint(customMessagesForm.providerRestrictedCountry, 'PROVIDER_RESTRICTED_COUNTRY');
                                break;
                            case 'PROVIDER_RESTRICTED_JURISDICTION':
                                showCustomHint(customMessagesForm.providerRestrictedJurisdiction, 'PROVIDER_RESTRICTED_JURISDICTION');
                                break;
                            case 'TOKEN_EXPIRED':
                                showCustomHint(customMessagesForm.tokenExpired, 'TOKEN_EXPIRED');
                                break;
                            case 'USER_ALREADY_EXISTS':
                                showCustomHint(customMessagesForm.userAlreadyExists, 'USER_ALREADY_EXISTS');
                                break;
                            case 'EMAIL_NOT_VERIFIED':
                                showCustomHint(customMessagesForm.emailNotVerified, 'EMAIL_NOT_VERIFIED');
                                break;
                            case 'INVALID_AUTHORIZATION':
                                showCustomHint(customMessagesForm.invalidAuthorization, 'INVALID_AUTHORIZATION');
                                break;
                            case 'INVALID_CREDENTIALS':
                                showCustomHint(customMessagesForm.invalidCredentials, 'INVALID_CREDENTIALS');
                                break;
                            case 'INVALID_TOKEN':
                                showCustomHint(customMessagesForm.invalidToken, 'INVALID_TOKEN');
                                break;
                            case 'LOCKED_BY_CUSTOMER_REQUEST':
                                showCustomHint(customMessagesForm.lockedByCustomerRequest, 'LOCKED_BY_CUSTOMER_REQUEST');
                                break;
                            case 'LOCKED_BY_EDD_CHECKS':
                                showCustomHint(customMessagesForm.lockedByEddChecks, 'LOCKED_BY_EDD_CHECKS');
                                break;
                            case 'LOCKED_BY_FRAUD_AML':
                                showCustomHint(customMessagesForm.lockedByFraudAml, 'LOCKED_BY_FRAUD_AML');
                                break;
                            case 'LOCKED_BY_FRAUD_BONUS_ABUSE':
                                showCustomHint(customMessagesForm.lockedByFraudBonusAbuse, 'LOCKED_BY_FRAUD_BONUS_ABUSE');
                                break;
                            case 'LOCKED_BY_FRAUD_CHARGEBACK':
                                showCustomHint(customMessagesForm.lockedByFraudChargeback, 'LOCKED_BY_FRAUD_CHARGEBACK');
                                break;
                            case 'LOCKED_BY_FRAUD_EDITED_DOCUMENTS':
                                showCustomHint(customMessagesForm.lockedByFraudEditedDocuments, 'LOCKED_BY_FRAUD_EDITED_DOCUMENTS');
                                break;
                            case 'LOCKED_BY_FRAUD_MULTIPLE_ACCOUNTS':
                                showCustomHint(customMessagesForm.lockedByFraudMultipleAccounts, 'LOCKED_BY_FRAUD_MULTIPLE_ACCOUNTS');
                                break;
                            case 'LOCKED_BY_FRAUD_THIRD_PARTY_USAGE':
                                showCustomHint(customMessagesForm.lockedByFraudThirdPartyUsage, 'LOCKED_BY_FRAUD_THIRD_PARTY_USAGE');
                                break;
                            case 'LOCKED_BY_FRAUDULENT_BEHAVIOR':
                                showCustomHint(customMessagesForm.lockedByFraudulentBehavior, 'LOCKED_BY_FRAUDULENT_BEHAVIOR');
                                break;
                            case 'LOCKED_BY_SELF_EXCLUSION':
                                showCustomHint(customMessagesForm.lockedBySelfExclusion, 'LOCKED_BY_SELF_EXCLUSION');
                                break;
                            case 'LOCKED_BY_SPELPAUS':
                                showCustomHint(customMessagesForm.lockedBySpelpaus, 'LOCKED_BY_SPELPAUS');
                                break;
                            case 'LOCKED_BY_SUSPECTED_FRAUD':
                                showCustomHint(customMessagesForm.lockedBySuspectedFraud, 'LOCKED_BY_SUSPECTED_FRAUD');
                                break;
                            case 'LOCKED_BY_SUSPENDED':
                                showCustomHint(customMessagesForm.lockedBySuspended, 'LOCKED_BY_SUSPENDED');
                                break;
                            case 'LOCKED_BY_WRONG_PASSWORD':
                                showCustomHint(customMessagesForm.lockedByWrongPassword, 'LOCKED_BY_WRONG_PASSWORD');
                                break;
                            case 'PHONE_NOT_VERIFIED':
                                showCustomHint(customMessagesForm.phoneNotVerified, 'PHONE_NOT_VERIFIED');
                                break;
                            case 'UNAUTHENTICATED':
                                showCustomHint(customMessagesForm.unauthenticated, 'UNAUTHENTICATED');
                                break;
                            case 'UNAUTHORIZED':
                                showCustomHint(customMessagesForm.unauthorized, 'UNAUTHORIZED');
                                break;
                            default:
                                showCustomHint(customMessagesForm.loginFailed, 'login_failed');
                        }
                    } else {
                        showCustomHint(customMessagesForm.loginFailed, 'login_failed');
                    }
                }
            }, 'json');
        });
    });

    let lastRegistrationTime = 0; // Initialize with 0 or Date.now()
    const registrationCooldown = 10000;

    $('#register-popup form').on('submit', function(e) {
        e.preventDefault();

        const $registerButton = $('.register-button-with-load-animation');
        const currentTime = Date.now();

        if (currentTime - lastRegistrationTime < registrationCooldown) {
            showCustomHint("Please wait before trying again.", 'cooldown_active');
            return;
        }

        lastRegistrationTime = currentTime;

        const password = $('#register-password').val();
        //console.log("Password entered:", password);

        const passwordRegex = /(?=.*\d)(?=.*\D)(?!.*\s$).{8,69}$/;

        if (!passwordRegex.test(password)) {
            showCustomHint(customMessagesForm.passwordRequirements, 'custom_password_requirements');
            return;
        }

        // Show loading state
        $registerButton.addClass('loading');
        $registerButton.find('.spinner').show();

        // Store the original text of the button
        const originalText = $registerButton.text().trim();

        // Replace the button text with "Loading..."
        $registerButton.contents().filter(function() {
            return this.nodeType === 3; // NodeType 3 is a text node
        }).remove();  // Remove existing text nodes to avoid multiple replacements

        $registerButton.append('Loading...'); // Add the new single text node
        //console.log("Button text set to 'Loading...'");

        const registrationFormData = {
            action: 'handle_registration',
            email: $('#register-email').val(),
            password: password,
            country: $('#country-select').val(),
            currency: 'USD',
            offers: $('#receiveOffers').is(':checked') ? '1' : '0',
            landing_uri: $('#landing-uri').val(),
            referral_uri: $('#referral-uri').val(),
            affiliate_id: $('#affiliate-id').val(),
            affiliate_tag: $('#affiliate-tag').val(),
            nonce: myAjax.nonce,
            locale: localeCode
        };

        $.post(myAjax.ajaxurl, registrationFormData, function(response) {

            // Hide loading state
            $registerButton.removeClass('loading');
            $registerButton.find('.spinner').hide();

            // Restore the original button text
            $registerButton.contents().filter(function() {
                return this.nodeType === 3;
            }).remove();  // Clear any existing text nodes again

            $registerButton.append(originalText); // Restore the original text

            if (response.success) {
                //console.log("Registration successful. Hiding form and showing success message.");
                $('#register-popup').hide();
                $('#success-message-popup').css('display', 'flex');
            } else {
                //console.log("Registration failed. Response:", response);

                if (response.data && response.data.custom_code === 'custom_this_email_is_linked') {
                    //console.log("Email is already linked.");
                    showCustomHint(customMessagesForm.alreadyLinkedEmail, 'custom_this_email_is_linked');
                } else {
                    //console.log("General registration failure.");
                    showCustomHint(customMessagesForm.registrationFailed, 'custom_registration_failed');
                }
            }
        });
    });

    function checkUserProfile(afterLogin = false) {
        const sessionToken = sessionStorage.getItem('token');
        const API_URL = myScriptData.CASINO_API;

        if (!sessionToken) {
            return;
        }

        fetch(`${API_URL}/api/users/current`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionToken}`
            }
        })
            .then(response => response.json())
            .then(response => {
                if (response && response.data && response.data.user) {
                    const user = response.data.user;

                    if (!user.firstName || !user.lastName || !user.address1 || !user.birthDate || !user.city || !user.gender || !user.phone) {
                        showProfileUpdatePopup();
                        if (afterLogin) {
                            showCustomHint("Please complete your profile to access all features.", 'complete_profile');
                        }
                    } else if (afterLogin) {
                        window.location.reload();
                    }
                } else {
                    // Handle case where no user data is found
                }
            })
            .catch(error => {
                // Handle fetch error
            });
    }

    function showProfileUpdatePopup() {
        popupHandler.closePopups();
        $('#profile-update-popup').css('display', 'block');
        $('#modal-backdrop-semi-transparent').css('display', 'block');
    }

    function hideProfileUpdatePopup() {
        $('#profile-update-popup').hide();
        $('#modal-backdrop-semi-transparent').hide();
    }

    $('.close-modal').on('click', function() {
        hideProfileUpdatePopup();
    });

    $('#update-phone').on('input', function() {
        const phoneCode = $('#update-phone-code').val();
        const phone = $(this).val();
        
        if (phone 
            && phone.length > 3) {
        
            const formattedPhone = `${phoneCode}${phone}`;
    
            // Check phone number validity on each input
            checkPhoneNumber(formattedPhone).done(function(response) {
                if ((response.data.phoneInfo.valid === true && response.data.phoneInfo.mobile === true) ||
                    (response.data.phoneInfo.valid === "true" && response.data.phoneInfo.mobile === "true")) {
                    // Phone number is valid
                    $('#update-phone').removeClass('invalid-phone').addClass('valid-phone');
                    //hideCustomHint('invalid_phone');
                } else {
                    // Phone number is invalid or not a mobile number
                    $('#update-phone').removeClass('valid-phone').addClass('invalid-phone');
                    showCustomHint('Invalid phone number or not a mobile number.', 'invalid_phone');
                }
            }).fail(function() {
                $('#update-phone').removeClass('valid-phone').addClass('invalid-phone');
                showCustomHint('Failed to validate phone number.', 'phone_validation_failed');
            });

        } else {
        
            $('#update-phone').removeClass('valid-phone').addClass('invalid-phone');
            showCustomHint('Failed to validate phone number.', 'phone_validation_failed');
        
        }
        
    });

    $('#profile-update-form').on('submit', function(e) {
        e.preventDefault();

        const birthDay = $('#update-birth-day').val();
        const birthMonth = $('#update-birth-month').val();
        const birthYear = $('#update-birth-year').val();

        if (!birthDay || !birthMonth || !birthYear) {
            showCustomHint('All birth date fields are required.', 'birth_date_required');
            return;
        }

        // Pad birthDay and birthMonth with leading zeros if necessary
        const paddedBirthDay = birthDay.padStart(2, '0');
        const paddedBirthMonth = birthMonth.padStart(2, '0');
        const birthDate = `${birthYear}-${paddedBirthMonth}-${paddedBirthDay}`;


        const phoneCode = $('#update-phone-code').val();
        const phone = $('#update-phone').val();
        const formattedPhone = `${phoneCode}${phone}`;

        // Check phone number first
        checkPhoneNumber(formattedPhone).done(function(response) {
            if ((response.data.phoneInfo.valid === true && response.data.phoneInfo.mobile === true) ||
                (response.data.phoneInfo.valid === "true" && response.data.phoneInfo.mobile === "true")) {
                // If phone number is valid and it is a mobile number, proceed with profile update
                updateProfile(birthDate, formattedPhone);
            } else {
                // Show error message if phone number is invalid or not a mobile number
                showCustomHint('Invalid phone number or not a mobile number.', 'invalid_phone');
            }
        }).fail(function() {
            showCustomHint('Failed to validate phone number.', 'phone_validation_failed');
        });
    });

    function checkPhoneNumber(phoneNumber) {
        const API_URL = myScriptData.CASINO_API;
        return $.ajax({
            url: `${API_URL}/api/users/phone-info`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({ phone: phoneNumber })
        });
    }

    function updateProfile(birthDate, formattedPhone) {
        const profileUpdateFormData = {
            action: 'handle_profile_update',
            firstName: $('#update-first-name').val(),
            lastName: $('#update-last-name').val(),
            gender: $('#update-gender').val(),
            birthDate: birthDate,
            address1: $('#update-address').val(),
            city: $('#update-city').val(),
            postcode: $('#update-postcode').val(),
            phone: formattedPhone,
            nonce: myAjax.nonce,
            token: sessionStorage.getItem('token')
        };

        $.post(myAjax.ajaxurl, profileUpdateFormData, function(response) {
            if(response.success) {
                hideProfileUpdatePopup();
            } else {
                ////console.log(response.data.custom_code); // Debugging
                showCustomHint(response.data.message || customMessagesForm.updateFailed, 'profile_update_failed');
            }
        });
    }

    checkUserProfile();
});

window._AutofillCallbackHandler = window._AutofillCallbackHandler || function(){};